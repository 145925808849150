import styled from '@emotion/styled';

export const ToogleBar = styled.div`
  background-color: var(--chakra-colors-base-200);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  button {
    border: none;
    background: unset;
    cursor: pointer;
    svg {
      vertical-align: text-top;
    }
  }
`;

export const Group = styled.div`
  // background: ${props => props.floating ? 'unset' : props.theme.base_color};
  z-index: 2;
  width: 100%;
  position: relative;
  margin-top: ${props => props.floating ? '-60px' : '0'};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: ${props => props.floating ? 'row' : 'column'};
  justify-content: space-around;
`;

export const Box = styled.div`
  background: ${props => props.theme.base_color};
  box-shadow: ${props => props.floating ? '0 10px 30px 0 rgba(0,0,0,0.21)' : 'unset'};
  border-radius: 3px;
  .int-widget-box {
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    > div {
      margin: 30px 20px;
      address {
        font-style: normal;
        margin-bottom: 0;
      }
      h4 {
        font-size: 0.9rem;
        margin-top: 0;
      }
      a {
        color: unset;
      }
      ul {
        margin: 0;
        li {
          margin: 0;
          list-style-type: none;
        }
      }
    }
  }
`;