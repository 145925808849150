import React              from 'react';
import { graphql }        from 'gatsby';
import Img                from 'gatsby-image';
import { Center, Button } from '@chakra-ui/react';

import { Link }          from '@interness/web-core/src/components/elements/Link/Link';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Display           from '@interness/theme-twentytwenty/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import CallToAction      from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';

function IndexPage({ data }) {
  return (
    <>
      <Carousel>
        {data.headerImages.images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Mounted>
        <Boxgroup floating={false}/>
        <Spacer/>
      </Mounted>
      <Wrapper width={1024}>
        <Spacer/>
        <section>
          <LiveAnnouncements/>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Weymann</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Herzlich willkommen bei Juwelier & Uhrmachermeister Weymann im Herzen von Lübbecke.</p>
              "Zeit und Funkeln“ lautet unser Motto, zu dem wir Sie rund um das Thema
              Schmuck, Uhren und Trauringe begleiten möchten.
            <p>Wir präsentieren Ihnen eine exklusive Auswahl aus echtem Schmuck, zeitgemäßen Uhren und einer Vielzahl an traumhaften Trauringen und Eheringen.</p>

            <p>Juwelier Weymann in Lübbecke ist seit 70 Jahren ein Begriff für ganz individuellen und persönlichen Service. 
              In unseren neugestalteten und modernen Geschäftsräumen möchten wir Ihnen ein noch schöneres Einkaufserlebnis bieten.</p>

            <MoreExpander>
            <p>Sie sind herzlich eingeladen, in der angenehmen Atmosphäre unserer Geschäftsräume zu verweilen und unser fein ausgewähltes Sortiment an Eheringen, Schmuck und Uhren zu entdecken.</p>

            <p>Wir nehmen uns Zeit für Sie und begleiten Sie mit unserer umfassenden und fachgerechten Beratung. Lassen Sie sich bei einer und kompetenten Beratung inspirieren. Gerne nehmen wir uns die Zeit für Sie.</p>

            <p><b>SCHMUCK ist unsere Leidenschaft.</b><br/>
            Schmuck ist Kreativität, Inspiration und Leidenschaft. Die Natürlichkeit und Authentizität unserer
            Schmuckkollektionen sind uns besonders wichtig. Lassen Sie sich verzaubern.</p>

            <p><b>UHREN sind unsere Zeitzeugen.</b><br/>
            Zeit ist eine knappe Ressource geworden. Sie vergeht, ohne dass wir sie anhalten könnten. Dabei gibt es so
            viele unvergessliche Augenblicke...</p>

            <p><b>TRAURINGE sind Ringe für die Ewigkeit.</b><br/>
            Ein Trauring ist Symbol für die Liebe und an jedem Tag die Erinnerung,  den schönsten Moment im Leben
            immer wieder neu erstrahlen zu lassen.</p>

            <p>Wir bieten Ihnen perfekten Service uns erstklassige Reparaturen.
            Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. <br/>
            Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.
            </p>

            <p>Wir freuen uns auf Ihren Besuch, Ihre Katrin Weymann und Team.</p>
            </MoreExpander>
          </span>
        </section>
        <Spacer/>
        <section>
          <Heading tag="h2">Erleben Sie die Vielfalt unserer Markenwelt</Heading>
          <Display/>
          <Spacer/>
        </section>
        <section>
          <Heading tag="h2" subtitle={'Neue Quellen für nachhaltigen Schmuck'}>Grünes Gold</Heading>
          <Center>
            <p>
              Das ZDF hat uns besucht! Und hat bei uns eine Dokumentation gedreht. Nun ist es endlich soweit und die
              Plan B Dokumentation über „grünes Gold“ ist sendebereit.
              Das ZDF war in unserer Werkstatt in Keltern, in Pforzheim bei „unserer" Gießerei Kalman und Hafner und in
              Sri Lanka, in den Edelstein Minen, aus denen wir unsere verantwortungsvoll gewonnenen Saphire beziehen.
            </p>
          </Center>
          <Center>
            <Button sx={{
              textDecoration: 'none',
            }} colorScheme="brand" as={Link} external
                    to="https://www.zdf.de/gesellschaft/plan-b/plan-b-gruenes-gold-100.html">Zum
              Video von ZDF</Button>
          </Center>
        </section>
        <Spacer/>
      </Wrapper>
      <CallToAction/>
    </>
  )
}

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;