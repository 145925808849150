import { useState, useEffect } from 'react';

const Mounted = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (mounted) {
    return children
  } else {
    return null;
  }
};

export default Mounted;