import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  border-bottom: 1px solid lightgray;
  &:last-of-type {
    border-bottom: none;
  }
  .int-col {
    width: 50%;
    padding-bottom: 20px;
    &.int-imageCol {
     .gatsby-image-wrapper {
        border: 1px solid lightgray;
      }
    }
    @media (max-width: 960px) {
      width: 100%;
      &.int-imageCol {
        order: -1;
        margin-bottom: 20px;
        padding-right: 0 !important;
     }
     &.int-textCol {
        padding-right: 0 !important;
     }
    }
    &.int-textCol {
      text-align: justify;
      padding-right: 40px;
      h2 {
        margin-top: 0;
        hyphens: auto;
      }
    }
  }
  &:nth-child(even) {
   .int-imageCol {
      order: -1;
      padding-right: 40px;
      padding-left: 0;
   }
  }
`;
