import React, { useContext }          from 'react';
import PropTypes                      from 'prop-types';
import { useStaticQuery, graphql }    from 'gatsby';
import Img                            from 'gatsby-image';
import { Box, Flex, Button, Heading } from '@chakra-ui/react';

import { findT }                     from '@interness/web-core/src/components/_helpers';
import Link                          from '@interness/web-core/src/components/elements/Link/Link';
import { LanguageContext }           from '@interness/web-core/src/providers';
import routes                  from '@interness/web-core/config/routes';
import { getRandomBrandImage } from '@interness/brands-addon/src/components/_helpers';

import * as S from './styles';

const orderTypes = (types, order) => {
  types.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
  return types
};


const Display = ({ exclude, order, buttonPrimary, inserts, textOverride }) => {
  const { brands, brand_types } = useStaticQuery(query);
  const { language } = useContext(LanguageContext);
  const usedTypes = [];
  brands.nodes.forEach(({ brand }) => {
    if (!usedTypes.includes(brand.type.type) && !exclude.includes(brand.type.type)) {
      usedTypes.push(brand.type.type)
    }
  });
  const displayTypes = orderTypes(brand_types.nodes.filter(brand_type => usedTypes.includes(brand_type.type)), order);

  return (
    <S.Container>
      {inserts && inserts.map((insert, i) => {
        const isEven = i % 2 === 0;
        return (
          <Flex key={insert.title}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={isEven ? 'row-reverse' : 'row'}
                mt={'140px'}
                mb={'140px'}
                bg="base.200"
                rounded={'2xl'}
                position="relative"
                maxHeight="300px">
            <Box w={['100%', '100%', '60%']} p={'12'}>
              <Heading as='h3' size='md' fontWeight="bold">{insert.title}</Heading>
              <div>{insert.description}</div>
              <Button variant={'ghost'} colorScheme="brand" style={{ textDecoration: 'none' }}
                      ml="-15px"
                      as={Link}
                      to={insert.link}>Mehr erfahren</Button>
            </Box>
            <Box w={['100%', '100%', '40%']}>
              <Box rounded={'2xl'}
                   boxShadow={'2xl'}
                   overflow={'hidden'}
                   ml={['auto', 'auto', '50px']}
                   mr={['auto', 'auto', '50px']}
                   mt={['-30px', '-30px', '-130px']}
                   maxW={['120px', '200px', '100%']}
                   textAlign='center'
              >
                <Img fluid={insert.image}/>
              </Box>
            </Box>
          </Flex>
        );
      })}
      {displayTypes.map((displayType, i) => {
        const translations = findT(displayType.translations, 'de');
        const image = getRandomBrandImage(brands.nodes, displayType.type, 'gallery_images_square');
        const override = textOverride[displayType.type];
        const isEven = i % 2 === 0;
        return (
          <Flex key={displayType.type}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={isEven ? 'row-reverse' : 'row'}
                mt={'140px'}
                mb={'140px'}
                bg="base.200"
                rounded={'2xl'}
                position="relative"
                maxHeight="300px">
            <Box w={['100%', '100%', '60%']} p={'12'}>
              <Heading as='h3' size='md' fontWeight="bold">{translations.display_name}</Heading>
              <div dangerouslySetInnerHTML={{ __html: translations.short_description }}/>
              <Button variant={'ghost'} colorScheme="brand" style={{ textDecoration: 'none' }}
                      ml="-15px"
                      as={Link}
                      to={`${routes[displayType.type][language]}`}>Mehr erfahren</Button>
            </Box>
            <Box w={['100%', '100%', '40%']}>
              <Box rounded={'2xl'}
                   boxShadow={'2xl'}
                   overflow={'hidden'}
                   ml={['auto', 'auto', '50px']}
                   mr={['auto', 'auto', '50px']}
                   mt={['-30px', '-30px', '-130px']}
                   maxW={['120px', '200px', '100%']}
                   textAlign='center'
              >
                <Img fluid={image.gallery_images_square[0].file.localFile.childImageSharp.fluid}/>
              </Box>
            </Box>
          </Flex>
        )
      })}
    </S.Container>
  )

};

Display.propTypes = {
  exclude: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.arrayOf(PropTypes.string),
  textOverride: PropTypes.object,
};

Display.defaultProps = {
  exclude: [''],
  order: [''],
  textOverride: {},
};

const query = graphql`
    query {
        brands: allDirectusBrands(filter: {brand: {status: {eq: "published"}}, id: {ne: "dummy"}}) {
            nodes {
                brand {
                    display_name
                    type {
                        type
                    }
                    gallery_images_square {
                        file {
                            width
                            height
                            localFile {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 700, cropFocus: CENTER) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        brand_types: allDirectusBrandTypes(filter: {id: {ne: "dummy"}}) {
            nodes {
                type
                translations {
                    display_name
                    language
                    short_description
                    subtitle
                    description
                }
            }
        }
    }
`;

export default Display;
