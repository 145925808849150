const getRandom = (array) => {
  return array.sort(() => .5 - Math.random()).slice(0, 1)[0].brand;
}

export const getRandomBrandImage = (brands, brandType, imageType) => {
  if (!imageType) {
    imageType = 'header_images'
  }
  let brandValid = false;
  let randomBrand;

  if (brandType !== 'all') {
    brands = brands.filter(({ brand }) => brand.type.type === brandType);
  }

  while (!brandValid) {
    randomBrand = getRandom(brands);
    if (randomBrand.display_name !== 'Rolex' && randomBrand[imageType].length !== 0) {
      brandValid = true;
    }
  }

  return randomBrand;
};