import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import { useStaticQuery, graphql }    from 'gatsby';
import DirectusSDK                    from '@directus/sdk-js';
import { TiNews }                     from 'react-icons/ti';
import { Box, useTheme }              from '@chakra-ui/react';

import Heading            from '../../text/Heading/Heading';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import Carousel           from '../../media/Carousel/Carousel';
import LivePromoCard      from '../../media/LivePromoCard/LivePromoCard';

const CarouselWrapper = ({ children, carouselOptions }) => {
  const theme = useTheme();
  return (
    <Box sx={{
      '.int-custom .slick-dots': {
        bottom: '0 !important',
      },
      '.int-custom .slick-dots li button': {
        backgroundColor: theme.colorMode === 'light' ? '#8a8a8a' : '#fff',
      },
      '.int-custom .slick-dots li.slick-active button': {
        backgroundColor: theme.brand_color,
      }
    }}>
      <Carousel options={carouselOptions}>{children}</Carousel>
    </Box>
  )
}

const LiveAnnouncements = ({ showHeading }) => {

  const client = new DirectusSDK({
    url: process.env.GATSBY_API_ENDPOINT,
    project: 'announcements',
  });

  const project_name = useStaticQuery(query).site.siteMetadata.projectName;
  const [announcementsRequest, setAnnouncementRequest] = useState({ loading: false, announcements: null, error: null });

  const carouselOptions = {
    adaptiveHeight: true,
    dots: true,
    autoplay: false,
    infinite: false,
  };

  useEffect(() => {
    const fetchData = async () => {
      setAnnouncementRequest({ loading: true });
      let data;
      try {
        data = await client.getItems('announcement', {
          status: 'published',
          'filter[project.domain]': process.env.NODE_ENV === 'development' ? 'development' : project_name,
          'filter[ending_at][gt]': 'now',
          fields: '*,image.*'
        });
        setAnnouncementRequest({
          loading: false,
          announcements: data.data,
        })
      } catch (e) {
        setAnnouncementRequest({ loading: false, error: e });
        console.log(e)
      }
    };

    fetchData();
    //eslint-disable-next-line
  }, []);

  const { announcements } = announcementsRequest;

  if (announcements && announcements.length !== 0) {
    return (
      <div>
        {showHeading &&
          <Heading tag="h2"
                   icon={<TiNews/>}>News {announcements.length > 1 ? `(${announcements.length})` : null}</Heading>}
        <ConditionalWrapper
          condition={announcements.length > 1}
          wrapper={children => <CarouselWrapper options={carouselOptions}>{children}</CarouselWrapper>}>
          {announcements.map(announcement => {
            return <LivePromoCard key={announcement.id} title={announcement.title} image={announcement.image}>
              <div dangerouslySetInnerHTML={{ __html: announcement.content }}/>
            </LivePromoCard>;
          })}
        </ConditionalWrapper>
      </div>
    )
  } else {
    return null;
  }
};

LiveAnnouncements.propTypes = {
  showHeading: PropTypes.bool,
}

LiveAnnouncements.defaultProps = {
  showHeading: true,
}

const query = graphql`
    query {
        site {
            siteMetadata {
                projectName
            }
        }
    }
`;

export default LiveAnnouncements;
