import styled        from '@emotion/styled';
import readableColor from 'polished/lib/color/readableColor';

export const Wrapper = styled.div`
  padding: 0 20px 40px 20px;
`;

export const Card = styled.div`
  background-color: var(--chakra-colors-brand-500);
  border-radius: var(--chakra-radii-2xl);
  box-shadow: var(--chakra-shadows-2xl);
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  max-height: 600px;
  @media all and (max-width: 1160px) {
    flex-direction: column-reverse;
    max-height: unset;
  }
`;

export const Text = styled.div`
  flex-shrink: 0;
  padding: 35px;
  width: ${props => props.hasImage ? '40%' : '100%'};
  //text-align: center;
  background-color: var(--chakra-colors-brand-500);
  color: ${props => readableColor(props.theme.colors.brand['500'])} !important;
  overflow: auto;
  a, h3, h4, p {
    color: ${props => readableColor(props.theme.colors.brand['500'])} !important;
  }
  .int-title {
    text-align: center;
    margin-top: 0;
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    hyphens: auto;
  }
  li {
    list-style-type: none;
  }
  p, li {
    font-size: 1.3rem;
  }
  @media all and (max-width: 1160px) {
    width: 100%;
  }
`;

export const Image = styled.div`
  width: 60%;
  overflow: hidden;
  flex-shrink: 1;
  img {
    margin: auto;
    width: 100%;
  }
  @media all and (max-width: 1160px) {
    width: 100%;
    max-height: 500px;
  }
`;
