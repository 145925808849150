import React, { useState }            from 'react';
import AnimateHeight                  from 'react-animate-height';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

import Widgets from '@interness/web-core/src/components/modules/Widgets';

import * as S             from './styles';
import ConditionalWrapper from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';

// TODO make configurable via api
const defaultWidgets = [
  {
    sort: 1,
    widget_name: 'address',
    props: {},
    translations: [
      {
        language: 'de',
        header: 'Adresse'
      }
    ]
  },
  {
    sort: 2,
    widget_name: 'contact',
    props: {},
    translations: [
      {
        language: 'de',
        header: 'Kontakt'
      }
    ]
  },
  {
    sort: 3,
    widget_name: 'openingHours',
    props: {},
    translations: [
      {
        language: 'de',
        header: 'Öffnungszeiten'
      }
    ]
  }
];

const Chevron = ({ direction }) => {
  if (direction === 'up') {
    return <FaChevronUp/>
  } else {
    return <FaChevronDown/>
  }
};

const Boxgroup = ({ floating, barColor }) => {
  const [open, setOpen] = useState(floating);

  const toggle = () => {
    setOpen(!open);
  }

  return (
    <S.Group floating={floating}>
      {!floating && <S.ToogleBar bg={barColor}>
        <button onClick={toggle}>Kontakt <Chevron direction={open ? 'up' : 'down'} /></button>
      </ S.ToogleBar>}
      <S.Box floating={floating}>
        <ConditionalWrapper condition={!floating} wrapper={children => <AnimateHeight height={open ? 'auto' : 0}>{children}</AnimateHeight>}>
          <div className='int-widget-box'>
            <Widgets widgets={defaultWidgets} lang={'de'}/>
          </div>
        </ConditionalWrapper>
      </S.Box>
    </S.Group>
  )
};

Boxgroup.defaultProps = {
  floating: true,
}

export default Boxgroup;
