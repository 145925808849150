import React                       from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { readableColor }           from 'polished';

import * as S           from './styles';
import { Button, Link } from '../../elements';

const LivePromoCard = ({ title, image, attachment, children, cta }) => {
  const { theme } = useStaticQuery(query);
  const button_color = readableColor(theme.brand_color, '#000', '#fff');
  return (
    <S.Wrapper>
      <S.Card>
        <S.Text hasImage={image !== null}>
          <h3 className={'int-title'}>{title}</h3>
          {children}
          {cta &&
          <Button primary accentColor={button_color} as={Link}
                  to={cta.type === '0' ? cta.link : attachment}>{cta.display_name}</Button>}
        </S.Text>
        {image && <S.Image>
          <img src={`${process.env.GATSBY_API_ENDPOINT}/announcements/assets/${image.private_hash}?w=800&h=700&f=contain&q=100`} alt='' />
        </S.Image>}
      </S.Card>
    </S.Wrapper>
  )
};

export default LivePromoCard;

const query = graphql`
  query {
    theme {
      brand_color
    }
  }
`;